/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * This class holds data to update an existing cart.
 */
export interface CartUpdate { 
    /**
     * Id of the cart
     */
    
    id: number;
    /**
     * Provided comment on cart level.
     */
    
    comment?: string | null;
    /**
     * Provide information, if cart is readOnly, or not.
     */
    
    locked: boolean;
    /**
     * Shows the cart state.
     */
    
    state: CartUpdateState;
    /**
     * The expiration date indicating how long the cart remains valid.
     */
    
    valid_until: Date;
}
export enum CartUpdateState {
    Created = 'Created',
    Active = 'Active',
    Expired = 'Expired',
    Closed = 'Closed',
    Ordered = 'Ordered',
    Done = 'Done'
};



