import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { SalesOption } from '@sales-libs/sc-solutions-conf/data-access';

export const imports = [
  CommonModule,
  TranslateModule,
  MatButtonModule,
  MatListModule,
  MatDialogModule,
];

@Component({
  selector: 'sl-sc-sales-options-dialog',
  standalone: true,
  imports: imports,
  templateUrl: './sales-options-dialog.component.html',
  styleUrls: ['./sales-options-dialog.component.scss'],
})
export class SlScSalesOptionsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      salesOptions: SalesOption[];
      displayPrices: boolean;
    },
  ) {}
}
