<h1 mat-dialog-title>
  {{ 'sales_shared.solution.chosen_sales_options' | translate }}
</h1>
<div mat-dialog-content>
  <mat-list *ngIf="data.salesOptions.length">
    <mat-list-item *ngFor="let option of data.salesOptions" class="no-padding">
      <span matListItemTitle>{{ option.code }}</span>
      <div matListItemMeta *ngIf="data.displayPrices">
        {{ option.price }} EUR
      </div>
      <p matListItemLine>{{ option.name }}</p>
    </mat-list-item>
  </mat-list>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
